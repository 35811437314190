import React from "react"
import Typography from "@material-ui/core/Typography"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ButtonBase from "../components/buttonbase"

const IndexPage = () => {
  return (
    <Layout contentOverImage>
      <SEO title="Home" />
      <div
        style={{
          margin: "8rem auto 8rem",
        }}
      >
        <Typography
          component="blockquote"
          variant="h6"
          style={{
            color: "white",
            position: "relative",
            textAlign: "center",
            top: "-1.75rem",
            marginBottom: "3rem",
            fontStyle: "italic",
          }}
        >
          "Filming is being in touch with the world"
        </Typography>
        <ButtonBase />
      </div>
    </Layout>
  )
}

export default IndexPage
