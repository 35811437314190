import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import ButtonBase from "@material-ui/core/ButtonBase"
import Typography from "@material-ui/core/Typography"
import Mariage from "../images/MariageMirror.jpg"
import Event from "../images/Event.jpg"
import Hotel from "../images/Hotel.jpg"
import Travel from "../images/Travel.jpg"
import { navigate } from "gatsby"

const images = [
  {
    url: Mariage,
    title: "WEDDING",
    width: "25%",
    to: "/services#serviceWedding ",
  },
  {
    url: Hotel,
    title: "ADVERTISING",
    width: "25%",
    to: "/services#serviceAdvertising",
  },
  {
    url: Event,
    title: "EVENT",
    width: "25%",
    to: "/services#serviceEvent",
  },

  {
    url: Travel,
    title: "TRAVEL",
    width: "25%",
    to: "/travel",
  },
]

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    minWidth: 300,
    width: "100%",
  },
  image: {
    position: "relative",
    height: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100% !important", // Overrides inline-style
      height: "15vh",
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      outline: "2px solid white",
      "& $imageButton": {
        outline: "2px solid white",
      },
      "& $imageBackdrop": {
        opacity: "0.1",
      },
    },
  },
  focusVisible: {},
  imageButton: {
    margin: "1rem",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "right center",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: "0.4",
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    letterSpacing: "0.5rem",
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) +
      6}px`,
  },
}))

export default function ButtonBases() {
  const classes = useStyles()

  if (typeof window !== "undefined") {
    return (
      <div className={classes.root}>
        {images.map(image => (
          <ButtonBase
            focusRipple
            key={image.title}
            className={classes.image}
            focusVisibleClassName={classes.focusVisible}
            style={{
              width: image.width,
            }}
            onClick={() => {
              navigate(image.to)
            }}
          >
            <span
              className={classes.imageSrc}
              style={{
                backgroundImage: `url(${image.url})`,
              }}
            />
            <span className={classes.imageBackdrop} />
            <span className={classes.imageButton}>
              <Typography
                component="span"
                variant="subtitle1"
                color="inherit"
                className={classes.imageTitle}
              >
                {image.title}
              </Typography>
            </span>
          </ButtonBase>
        ))}
      </div>
    )
  } else return <></>
}
